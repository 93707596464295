import * as React from "react";
import { Link } from "gatsby";
import { ChevronRight } from "react-feather";
import { Helmet } from "react-helmet";

import Theme from "../components/theme";
import Footer from "../components/footer";

// import hero from '../images/hero.jpg';

import "../styles/index.css";
import Hero from "../components/hero";
import CookieConsent from "../components/cookieConsent";
import Buchung from "../components/buchung";

const IndexPage = () => {
  return (
    <Theme>
      <Helmet>
        <title>Angela Hollstein - Coaching - Supervision - Workshops</title>
        <meta name="description" content="Coaching - Supervision - Workshops" />
        <meta
          name="keywords"
          content="supervision, coaching, freiburg, schweiz, deutschland, beratung, frauen"
        />
      </Helmet>
      {/* Hero */}
      <Hero
        title="Angebote"
        heading="Sie wissen, was Sie wollen. Ich weiß, wie wir Sie hinbringen!"
      />

      <div className="md:grid md:grid-cols-3 text-xl md:mt-20">
        <div className="m-3 py-14 flex flex-col justify-between z-10">
          <div>
            <h2 className="text-center text-2xl font-bold">Coaching</h2>
            <div className="flex justify-center">
              <p className="text-base mt-6 md:w-2/3">
                Coaching folgt einem definierten Ablauf und einer bewährten
                Struktur, die sich im Laufe einer Sitzung im besten Fall
                verselbständigt und dem Klienten zu seinem Ziel verhilft.
                <br />
                Als Expertin für die Struktur zeige ich Ihnen hilfreiche
                Richtungen auf, die Sie bestmöglich und nachhaltig zu Ihrem Ziel
                führen sollen
              </p>
            </div>
          </div>
          <div className="mt-6 flex justify-center">
            <Link to="/coaching" className="text-center">
              <p className="text-base p-3 font-sans bg-light-blue">
                Mehr erfahren{" "}
                <ChevronRight size={18} className="inline-block" />
              </p>
            </Link>
          </div>
        </div>

        <div className="m-3 py-14 flex flex-col justify-between">
          <div>
            <h2 className="text-center text-2xl font-bold">
              Systemische Supervision
            </h2>
            <div className="flex justify-center">
              <p className="text-base mt-6 md:w-2/3">
                Wir betrachten und reflektieren gemeinsam professionelles
                Handeln und ermöglichen so einen Perspektivwechsel.
              </p>
            </div>
          </div>
          <div className="mt-6 flex justify-center">
            <Link to="/supervision" className="text-center">
              <p className="text-base p-3 font-sans bg-light-blue mt-6">
                Mehr erfahren{" "}
                <ChevronRight size={18} className="inline-block" />
              </p>
            </Link>
          </div>
        </div>

        <div className="m-3 py-14">
          <div>
            <h2 className="text-center text-2xl font-bold">Workshops</h2>
            <div className="flex justify-center">
              <p className="text-base mt-6 md:w-2/3">
                Ich biete Workshops und Vorträge zu den Themen „Kinderschutz“
                und „Persönliche Weiterentwicklung in Beruf und Privatleben“ an.
              </p>
            </div>
          </div>
          <div className="mt-6 flex justify-center">
            <Link to="/workshop" className="text-center">
              <p className="text-base p-3 font-sans bg-light-blue mt-6">
                Mehr erfahren{" "}
                <ChevronRight size={18} className="inline-block" />
              </p>
            </Link>
          </div>
        </div>
      </div>

      <Buchung />

      <CookieConsent />

      <Footer />
    </Theme>
  );
};

export default IndexPage;
